<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          {{ ICON.ADD_PLUS }}
        </v-icon>
        {{ COMMON.ADD_AD_VIDEO }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline primary modaltext--text">
        {{ COMMON.ADD_AD_VIDEO }}
      </v-card-title>

      <v-dialog
        v-model="uploading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Uploading, Please wait...
            <v-progress-linear
              color="light-green darken-4"
              height="10"
              :value="progress"
              stripped
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-form
        ref="adVideoForm"
        @submit.prevent="onSubmit"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input
                v-model="video"
                accept="video/mp4"
                prepend-icon=""
                :prepend-inner-icon="ICON.VIDEO"
                outlined
                dense
                background-color="white"
                label="Select Ad Video"
                hide-details="auto"
                @change="uploadVideo"
              />
            </v-col>
            <template v-if="videoUploaded">
              <v-col cols="12">
                <v-select
                  v-model="adVideo.videoId"
                  :items="newsVideos"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  background-color="white"
                  label="Select Capsule"
                  multiple
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="adVideo.title"
                  outlined
                  dense
                  background-color="white"
                  label="Ad title"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="adVideo.placement"
                  :items="availablePlacements"
                  outlined
                  dense
                  background-color="white"
                  label="Select Placement"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>

              <!-- <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="adVideo.startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="adVideo.startDate"
                    label="Select Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="adVideo.startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(adVideo.startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="adVideo.endDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="adVideo.endDate"
                    label="Select End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="adVideo.endDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(adVideo.endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col> -->

              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Select Dates"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      :rules="[rules.required]"
                      hide-details="auto"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(dateRange)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            {{ COMMON.CANCEL_BTN }}
          </v-btn>
          <v-btn
            v-show="videoUploaded"
            color="primary"
            type="submit"
          >
            <span v-if="!isBusy"> Add </span>
            <v-progress-circular
              v-else
              indeterminate
            />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import common from '../../commons/common';

export default {
  name: 'AddAdVideo',

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,

      dialog: false,
      menu: false,
      dateRange: [],
      isBusy: false,
      videoUploaded: false,
      uploading: false,
      progress: 0,

      adVideo: {
        videoId: null,
        title: '',
        url: '',
        placement: 'START',
        startDate: '',
        endDate: '',
      },

      availablePlacements: ['START', 'END', 'BOTH'],

      newsVideos: [],

      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },

  computed: {
    dateRangeText() {
      return this.dateRange.join(' ~ ');
    },
  },

  watch: {
    dateRange: {
      handler(nv) {
        this.adVideo.startDate = `${nv[0]} 00:00:00`;
        this.adVideo.endDate = `${nv[1]} 00:00:00`;
      },
    },
  },

  created() {
    this.getNewsVideos();
  },

  methods: {
    async getNewsVideos() {
      const { data } = await this.$axios.get('/news-videos');
      this.newsVideos = data;
    },

    async uploadVideo(video) {
      let newAdVideo = new FormData();
      newAdVideo.append('file', video);

      try {
        this.uploading = true;
        this.progress = 0;
        const { data, status } = await this.$axios.post(
          '/ad-videos/upload',
          newAdVideo,
          {
            onUploadProgress: function(e) {
              this.progress = Math.round((e.loaded / e.total) * 100);
            },
          }
        );

        if (status === 200) {
          this.adVideo.url = data.url;
          this.adVideo.filename = data.filename;
          this.videoUploaded = true;
          this.$emit('enable-snack', 'Video uploaded');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.uploading = false;
      }
    },

    onSubmit() {
      if (this.$refs.adVideoForm.validate()) this.addAdVideo();
    },

    async addAdVideo() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.post('ad-videos', this.adVideo);
        if (status === 201) {
          this.$emit('on-add-create');
          this.$emit('enable-snack', 'Ad successfully created');

          this.cancel();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    cancel() {
      this.dialog = false;
      this.videoUploaded = false;
      this.$refs.adVideoForm.reset();
    },
  },
};
</script>

<style></style>
