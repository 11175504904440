<template>
  <div>
    <v-container>
      <v-row
        justify="space-between"
        align="center"
        class="mx-3 mb-6"
      >
        <div class="headline">
          {{ COMMON.LABEL_AD_VIDEOS }}
        </div>

        <add-ad-video
          @on-add-create="handleCreateAdd"
          @enable-snack="enableSnackbar"
        />
      </v-row>

      <v-data-table
        :headers="headers"
        :items="adVideos"
        :items-per-page="5"
        hide-default-footer
        class="elevation-1"
        :loading="isBusy"
        loading-text="Loading... Please wait"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template #[`item.capsule`]="{ item }">
          {{ item.newsVideo.title }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-row>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click="previewVideo(item)"
                >
                  <v-icon dark>
                    {{ ICON.VIDEO_PREVIEW }}
                  </v-icon>
                </v-btn>
              </template>
              <span> Preview Ad </span>
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click="editAdVideo(item)"
                >
                  <v-icon dark>
                    {{ ICON.EDIT_SQUARE }}
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ COMMON.EDIT_CAPSULE }} </span>
            </v-tooltip>
            <DeleteAd
              v-bind="item"
              @on-delete="deleteAd"
            />
          </v-row>
        </template>
      </v-data-table>

      <v-pagination
        v-model="page"
        :length="pageCount"
        class="mt-4"
      />
    </v-container>

    <v-dialog
      v-model="previewDialog"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        color="#FFFFFF"
        dark
      >
        <v-toolbar
          dark
          class="navbar--header"
        >
          <div class="d-flex mr-4 align-center">
            <v-img
              alt="News Audible"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              :src="require('@/assets/logo.png')"
              width="100"
            />
          </div>
          <v-spacer />

          <v-toolbar-title>
            Preview Ad
          </v-toolbar-title>

          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon>{{ ICON.CLOSE }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text v-if="previewVideoItem">
          <div
            class="my-6 secondary--text"
            style="text-align: center"
          >
            <h3>Title: {{ previewVideoItem.title }}</h3>
          </div>
          <v-row>
            <video-player
              v-if="previewDialog"
              :options="videoOptions"
              class="mx-auto"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <EditAdVideo
      v-model="showEdit"
      :ad-video-data="editableAd"
      @on-ad-update="getAdVideos()"
      @enable-snack="enableSnackbar"
    />
  </div>
</template>

<script>
import common from '@/commons/common';
import AddAdVideo from '../../components/ad-videos/AddAdVideo.vue';
import DeleteAd from '../../components/ad-videos/DeleteAd.vue';
import VideoPlayer from '../../components/VideoPlayer.vue';
import EditAdVideo from '../../components/ad-videos/EditAdVideo.vue';
// import { saveAs } from 'file-saver';

export default {
  name: 'AdVideos',

  components: { AddAdVideo, DeleteAd, VideoPlayer, EditAdVideo },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,

      adVideos: [],

      headers: [
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        { text: 'Capsule', value: 'capsule' },
        { text: 'Placement', value: 'placement' },
        { text: 'Start Date', value: 'startDate' },
        { text: 'End Date', value: 'endDate' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      page: 1,
      pageCount: 0,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,

      videoOptions: {
        autoplay: true,
        controls: true,
        height: 500,
        width: 600,
        liveui: true,
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },

      previewDialog: false,
      previewVideoItem: null,

      editableAd: null,

      showEdit: false,
    };
  },

  created() {
    this.getAdVideos();
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    handleCreateAdd() {
      this.getAdVideos();
    },

    async getAdVideos() {
      try {
        this.isBusy = true;
        const { data, status } = await this.$axios.get('/ad-videos');
        if (status === 200) {
          this.adVideos = data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    async deleteAd(id) {
      try {
        const { status } = await this.$axios.delete(`/ad-videos/${id}`);
        if (status == 200) {
          this.enableSnackbar('Ad deleted!');
          this.getAdVideos();
        }
      } catch (error) {
        console.log(error);
      }
    },

    previewVideo(item) {
      if (!item) return;
      this.previewVideoItem = item;
      this.videoOptions.sources[0].src = `${process.env.VUE_APP_BASE_URL}/ad-videos/stream/${item.id}`;
      this.previewDialog = true;
    },

    editAdVideo(item) {
      this.showEdit = true;
      this.editableAd = item;
    },

    closeDialog() {
      this.previewDialog = false;
    },
  },
};
</script>

<style></style>
